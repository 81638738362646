import React, { useState, useEffect, useRef } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAircraftListAction,
  getAircraftUserAction,
} from "../redux/actions/product.action";
import {
  getChatAction,
  getUserDocsAction,
  postChatAction,
} from "../redux/actions/chat.action";
import { getProfile } from "../redux/actions";
import pro1 from "../assets/pic2.jpg";
import pro2 from "../assets/pic1.jpg";

const ChatEngg = () => {
  const [question, setQuestion] = useState("");
  const [q, setQ] = useState(false);
  const [qn, setQn] = useState("");
  const [show, setShow] = useState(false);
  const [expand, setExpand] = useState(false);
  const [menu, setMenu] = useState(false);
  const [air, setAir] = useState("");
  const [airId, setAirId] = useState("");
  const [room, setRoom] = useState("Classroom");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { aircraftListData2 } = useSelector((state) => state.productReducer);
  const { chatListData, userDocsListData, error } = useSelector(
    (state) => state.chatReducer
  );
  const { profileData } = useSelector((state) => state.auth);

  const [aircraftUser, setAircraftUser] = useState("");
  const handleChange = (event) => {
    setAircraftUser(event.target.value);
    setMenu(false);
  };
  const id = aircraftUser;

  useEffect(() => {
    dispatch(getAircraftUserAction());
    dispatch(getAircraftListAction());
    dispatch(getUserDocsAction());
    dispatch(getProfile());
  }, []);

  const send = async (e) => {
    e.preventDefault();

    setQ(true);
    setLoading(true);
    setQn(question);
    const form = new FormData();
    form.append("participant", aircraftUser);
    form.append("question", question);
    form.append("type", room);
    setQuestion("");
    await dispatch(postChatAction(form, id, room));
    setQ(false);
    setLoading(false);
  };

  useEffect(() => {
    dispatch(getChatAction(id, room));
  }, [id, room]);

  const chatRef = useRef(null);
  useEffect(() => {
    chatRef.current?.scrollIntoView();
  }, [chatListData, q]);

  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  return (
    <div>
      <div className="header flex items-center px-8 py-4 z-50 bg-[#fff]">
        <Link to="/">
          <div className="flex items-center gap-3">
            {/* <img src={logo} className="w-24 h-12 cursor-pointer" alt="" /> */}
            <span className="text-[#008080] font-medium text-3xl">
              SkillNUX
            </span>
          </div>
        </Link>
        <div className="flex items-center gap-2">
          <li className="text-[#008080]">
            Hello <span className="font-medium ">{profileData?.username}!</span>
          </li>
          <i
            className="fa-solid fa-bars lg:hidden"
            onClick={() => setMenu(!menu)}></i>
        </div>
      </div>

      <div className="mt-16 flex relative">
        <div
          className={`lg:flex w-1/5 bg-black text-white p-4 z-20 ${
            menu === true ? "w-full fixed" : "hidden"
          }`}
          style={{ height: "calc(100vh - 65px)" }}>
          <div
            className={`fixed left-0 top-16 bg-black  h-screen text-white p-4 z-10 lg:block ${
              menu === true ? "w-full" : "w-1/5"
            }`}
            style={{ height: "calc(100vh - 65px)" }}>
            <div className="px-4">
              <div className="mt-3  ">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setExpand(!expand)}>
                  <div>
                    <i class="fa-solid fa-graduation-cap "></i>
                    <button className="ml-3">Courses</button>
                  </div>
                </div>

                <div className="overflow-hidden">
                  <div className="h-80 overflow-y-scroll -mr-6 pr-6">
                    {aircraftListData2?.map((data) => {
                      return (
                        <li
                          className={`mt-1 cursor-pointer text-sm ${
                            airId === data.id ? "text-[#1dd1d1]" : "text-white"
                          }`}
                          value={data.id}
                          key={data.id}
                          onClick={(e) => {
                            handleChange(e);
                            setAir(data.course);
                            setAirId(data.id);
                          }}>
                          {data.course}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-8 px-4">
              <div className="mt-2">
                <Link to="/settings">
                  <i class="fa-solid fa-gear text-sm"></i>
                  <span className="ml-2 text-sm">Settings</span>
                </Link>
              </div>
              <div className="mt-2 cursor-pointer" onClick={onLogout}>
                <i class="fa-solid fa-power-off text-sm"></i>
                <span className="ml-2 text-sm">Logout</span>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex-1">
          <div className={`relative  mb-20`}>
            <div className="flex justify-between fixed w-full lg:w-4/5 bg-[#d9d9d9] border-b border-[#ccc]">
              <div className="flex items-center">
                <li className="pl-4 py-2 font-medium text-[#008080]">
                  {air}
                  {/* {error?.status === 404 && " (Arcived)"} */}
                </li>
                {/* {error?.status === 400 && (
                  <small className="text-[#008080]"> {error?.data}</small>
                )} */}
                <small className="text-[#ff0000] mx-1">
                  {chatListData[chatListData?.length - 1]?.disable &&
                    " (Archived)"}
                </small>
                <small className="text-[#ff0000] mx-1">
                  {chatListData[chatListData?.length - 1]?.credit &&
                    " You don't have Credits "}
                </small>
                <small className="text-[#ff0000] mx-1">
                  {chatListData[chatListData?.length - 1]?.cap &&
                    " You don't have Caps"}
                </small>
                {console.log(chatListData)}
              </div>
              {air !== "" && (
                <div className="flex pr-8 gap-4">
                  <button
                    className={
                      room === "Classroom" ? "text-[#008080]" : "text-black"
                    }
                    onClick={() => setRoom("Classroom")}>
                    Lectures
                  </button>
                  <button
                    className={
                      room === "Material" ? "text-[#008080]" : "text-black"
                    }
                    onClick={() => setRoom("Material")}>
                    Books
                  </button>
                  <button
                    className={
                      room === "Internet" ? "text-[#008080]" : "text-black"
                    }
                    onClick={() => setRoom("Internet")}>
                    Internet
                  </button>
                </div>
              )}
            </div>
            <div className="pt-8">
              {chatListData?.length > 0 ? (
                chatListData?.slice(0, chatListData.length - 1).map((item) => (
                  <div className="" key={item.id}>
                    <div className="lg:px-32 px-8 py-4  border-b border-[#ccc] flex items-center gap-4">
                      <img
                        src={pro2}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                      <li className="pt-1 text-[#333] text-sm">
                        {item.question}
                      </li>
                    </div>
                    <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-center gap-4 bg-[#f5f5f5]">
                      <img
                        src={pro1}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        alt=""
                      />
                      <li className="pt-1 text-[#333] text-sm">
                        {item.answer}
                      </li>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="p-4 flex justify-center items-center"
                  style={{ height: "calc(100vh - 160px)" }}>
                  {error?.data !== "" && error?.status === 400 ? (
                    <li className="text-xl font-medium text-[#808080]">
                      {error?.data}
                    </li>
                  ) : (
                    <li className="text-xl font-medium text-[#808080]">
                      Please select course to initiate the chat
                    </li>
                  )}
                </div>
              )}
            </div>
            <div className={`mb-20`}>
              {q === true ? (
                <div
                  className={`lg:px-32 px-8 py-4 border-b border-[#aaa] flex items-center gap-4 bg-[#fff] `}>
                  <img
                    src={pro2}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <li className="text-sm">{qn}</li>
                </div>
              ) : null}
              {q === true && loading === true ? (
                <div
                  className={`lg:px-32 px-8 py-4 border-b border-[#aaa] flex items-center gap-4 bg-[#fff] `}>
                  <img
                    src={pro1}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <li className="">Loading...</li>
                </div>
              ) : null}

              {/* {q === true && status === 201 ? (
            <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-start gap-4 bg-[#ff9900]">
              <i className="fa-solid fa-user-tie  text-lg"></i>
              <li className=" ">{ans}</li>
            </div>
          ) : null} */}
            </div>

            <div ref={chatRef} />
            <div className=" w-full left-0 fixed bottom-0 bg-white ">
              <div className="lg:ml-96 lg:mr-28 relative  p-4">
                <form action="" onSubmit={send}>
                  <input
                    disabled={
                      chatListData[chatListData?.length - 1]?.disable ||
                      chatListData[chatListData?.length - 1]?.credit ||
                      chatListData[chatListData?.length - 1]?.cap
                        ? true
                        : false
                    }
                    className="chat-input"
                    type="text"
                    placeholder="write something"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />

                  <i
                    className="fa-solid fa-paper-plane absolute right-8 top-8"
                    onClick={send}></i>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <Add setShow={setShow} data={userDocsListData} />}
    </div>
  );
};

export default ChatEngg;

function Add({ setShow, data }) {
  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)] z-10 top-16 w-full h-screen"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px] fixed right-0  p-4 z-10 overflow-auto"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Documents</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        {data?.map((data) => {
          return (
            <div className="prod-input">
              <li className="text-[#808080] mt-2">
                - {data.filetag} {`(${data.aircraft_model}, ${data.type})`}
              </li>
              {/* <a
                target="_blank"
                href={data.file}
                className="mt-2 bg-black text-white text-sm px-4 py-[4px] inline-block rounded">
                View Document
              </a> */}
            </div>
          );
        })}
      </div>
    </>
  );
}
