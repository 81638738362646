import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  getAircraftListAction,
  getProfile,
  getUserDocsAction,
} from "../redux/actions";

const Settings = () => {
  const [eye, setEye] = useState(true);
  const [oldPass, setOldPass] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [show, setShow] = useState(false);
  const [expand, setExpand] = useState(false);
  const [menu, setMenu] = useState(false);

  const onEye = () => {
    setEye(!eye);
  };
  const { profileData, error, changePasswordData } = useSelector(
    (state) => state.auth
  );
  const { aircraftListData2 } = useSelector((state) => state.productReducer);
  const { userDocsListData } = useSelector((state) => state.chatReducer);

  const dispatch = useDispatch();
  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  const token = window.localStorage.getItem("token");
  const id = profileData?.id;

  const onPasswordSave = (e) => {
    e.preventDefault();
    const form = new FormData();

    form.append("old_password", oldPass);
    form.append("password", password);
    form.append("password2", password2);
    dispatch(changePasswordAction(form, id));
  };

  if (changePasswordData.status === 200) {
    localStorage.removeItem("token", token);
    window.location.href = "/login";
  }

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getAircraftListAction());
    dispatch(getUserDocsAction());
  }, []);

  return (
    <div>
      <div className="header flex items-center px-8 py-4 z-50 bg-[#fff]">
        <Link to="/">
          <div className="flex items-center gap-3">
            {/* <img src={logo} className="w-24 h-12 cursor-pointer" alt="" /> */}
            <span className="text-[#008080] font-medium text-3xl">
              SkillNUX
            </span>
          </div>
        </Link>
        <div className="flex items-center gap-2">
          <li className="text-[#008080]">
            Hello <span className="font-medium ">{profileData?.username}!</span>
          </li>
          <i
            className="fa-solid fa-bars lg:hidden"
            onClick={() => setMenu(!menu)}></i>
        </div>
      </div>

      <div className="mt-16 flex relative">
        <div
          className={`lg:flex w-1/5 bg-black text-white p-4 z-20 ${
            menu === true ? "w-full fixed" : "hidden"
          }`}
          style={{ height: "calc(100vh - 65px)" }}>
          <div
            className={`fixed left-0 top-16 bg-black  h-screen text-white p-4 z-10 lg:block ${
              menu === true ? "w-full" : "w-1/5"
            }`}
            style={{ height: "calc(100vh - 65px)" }}>
            <div className="px-4">
              <div className="mt-3  ">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => setExpand(!expand)}>
                  <div>
                    <i class="fa-solid fa-graduation-cap "></i>
                    <button className="ml-3">Courses</button>
                  </div>
                </div>

                <div className="overflow-hidden">
                  <div className="h-80 overflow-y-scroll -mr-6 pr-6">
                    {aircraftListData2?.map((data) => {
                      return (
                        <li
                          className="mt-1 cursor-pointer"
                          value={data.id}
                          key={data.id}
                          onClick={(e) => {
                            // handleChange(e);
                            // setAir(data.course);
                          }}>
                          {data.course}
                        </li>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-8 px-4">
              <div className="mt-2">
                <Link to="/settings">
                  <i class="fa-solid fa-gear"></i>
                  <span className="ml-2">Settings</span>
                </Link>
              </div>
              <div className="mt-2 cursor-pointer" onClick={onLogout}>
                <i class="fa-solid fa-power-off "></i>
                <span className="ml-2">Logout</span>
              </div>
            </div>
          </div>
        </div>

        <div className="p-3">
          <div className="table-box p-3">
            <li style={{ fontWeight: "bold" }}> Change password </li>
            <div className="login-input mt-2">
              <input
                type={eye ? "password" : "text"}
                placeholder="Enter old Password"
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                style={{ backgroundColor: "#fff" }}
              />
              <span onClick={onEye} className="login-eye">
                {eye ? (
                  <i className="eye fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="eye fa-solid fa-eye"></i>
                )}
              </span>
              {oldPass.length === 0 ? (
                <li className="mt-2" style={{ color: "#CD5437" }}>
                  This field may not be blank
                </li>
              ) : null}
              {error?.status === 400 && (
                <li className="mt-2" style={{ color: "#CD5437" }}>
                  Old password do not match
                </li>
              )}
            </div>

            <div className="login-input mt-4">
              <input
                type={eye ? "password" : "text"}
                placeholder="Enter new Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ backgroundColor: "#fff" }}
              />
              <span onClick={onEye} className="login-eye">
                {eye ? (
                  <i className="eye fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="eye fa-solid fa-eye"></i>
                )}
              </span>
              {error?.data?.password?.length > 0 && (
                <li className="mt-2" style={{ color: "#CD5437" }}>
                  {error?.data?.password}
                </li>
              )}
            </div>

            <div className="login-input mt-4">
              <input
                type={eye ? "password" : "text"}
                placeholder="Re-enter Password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                style={{ backgroundColor: "#fff" }}
              />
              <span onClick={onEye} className="login-eye">
                {eye ? (
                  <i className="eye fa-solid fa-eye-slash"></i>
                ) : (
                  <i className="eye fa-solid fa-eye"></i>
                )}
              </span>
              {password !== password2 && password2.length !== 0 ? (
                <li className="mt-2" style={{ color: "#CD5437" }}>
                  Password and Re-Password do not match
                </li>
              ) : null}
              <li className="mt-2" style={{ color: "#CD5437" }}>
                {error?.data?.password2}
              </li>
            </div>
            {/* <li className="text-danger mt-2">{error}</li> */}
            {error?.status === 400 && (
              <li className="text-danger mt-2">
                Old password do not match or password is weak
              </li>
            )}
            <div className="mt-4">
              <Button
                variant="contained"
                className="mt-4"
                onClick={onPasswordSave}
                // disabled={isBlur}
                style={{
                  backgroundColor: "#000",
                }}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {show && <Add setShow={setShow} data={userDocsListData} />}
    </div>
  );
};

export default Settings;

function Add({ setShow, data }) {
  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)] z-10 top-16 w-full h-screen"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px] fixed right-0  p-4 z-10 overflow-auto"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Documents</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        {data?.map((data) => {
          return (
            <div className="prod-input">
              <li className="text-[#808080] mt-2">
                - {data.filetag} {`(${data.aircraft_model}, ${data.type})`}
              </li>
              {/* <a
                target="_blank"
                href={data.file}
                className="mt-2 bg-black text-white text-sm px-4 py-[4px] inline-block rounded">
                View Document
              </a> */}
            </div>
          );
        })}
      </div>
    </>
  );
}
