import React, { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import "aos/dist/aos.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import Chat from "./pages/Chat";
import Product from "./pages/Product";
import Settings from "./pages/Settings";
import ChatEngg from "./pages/ChatEngg";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, isUserLoggedIn } from "./redux/actions";
import PrivateRoute from "./pages/PrivateRoute";

function App() {
  const dispatch = useDispatch();

  const { authenticate, profileData } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!authenticate) {
      dispatch(isUserLoggedIn);
    }
  }, [authenticate]);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/demo" exact element={<Chat />} />
          <Route element={<PrivateRoute />}>
            {profileData.role === "Admin" && (
              <Route path="/product" exact element={<Product />} />
            )}
            {profileData.role === "Participant" && (
              <Route path="/chat-engineer" exact element={<ChatEngg />} />
            )}

            <Route path="/settings" exact element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
