import React, { useState, useRef, useEffect } from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Product from "../components/Product";
import Product2 from "../components/Product2";
import Product3 from "../components/Product3";
import Product4 from "../components/Product4";
import { Link, Navigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { getProfile, login } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  // const [currentSection, setCurrentSection] = useState("hero");

  // const heroRef = useRef(null);
  // const aboutRef = useRef(null);
  // const productRef = useRef(null);
  // const product2Ref = useRef(null);
  // const product3Ref = useRef(null);
  // const product4Ref = useRef(null);
  // const contactRef = useRef(null);

  // const scrollToSection = (section) => {
  //   if (section === "hero") {
  //     heroRef.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "about") {
  //     aboutRef.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "product") {
  //     productRef.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "product2") {
  //     product2Ref.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "product3") {
  //     product3Ref.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "product4") {
  //     product4Ref.current.scrollIntoView({ behavior: "smooth" });
  //   } else if (section === "contact") {
  //     contactRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const heroOffset = heroRef.current.offsetTop;
  //     const aboutOffset = aboutRef.current.offsetTop;
  //     const productOffset = productRef.current.offsetTop;
  //     const product2Offset = product2Ref.current.offsetTop;
  //     const product3Offset = product3Ref.current.offsetTop;
  //     const product4Offset = product4Ref.current.offsetTop;
  //     const contactOffset = contactRef.current.offsetTop;
  //     const currentPosition = window.scrollY + 350;

  //     if (currentPosition >= heroOffset) {
  //       setCurrentSection("hero");
  //     }
  //     if (currentPosition >= aboutOffset) {
  //       setCurrentSection("about");
  //     }
  //     if (currentPosition >= productOffset) {
  //       setCurrentSection("product");
  //     }
  //     if (currentPosition >= product2Offset) {
  //       setCurrentSection("product2");
  //     }
  //     if (currentPosition >= product3Offset) {
  //       setCurrentSection("product3");
  //     }
  //     if (currentPosition >= product4Offset) {
  //       setCurrentSection("product4");
  //     }
  //     if (currentPosition >= contactOffset) {
  //       setCurrentSection("contact");
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [currentSection]);

  // const [page, setPage] = useState(currentSection);

  // useEffect(() => {
  //   setPage(currentSection);
  // }, [currentSection]);

  //   console.log(page);

  return (
    <div>
      <ChatBot />
      {/* <Header page={page} scrollToSection={scrollToSection} />
      <Hero page={page} heroRef={heroRef} scrollToSection={scrollToSection} />
      <About page={page} aboutRef={aboutRef} />
      <Product page={page} productRef={productRef} />
      <Product2 page={page} productRef={product2Ref} />
      <Product3 page={page} productRef={product3Ref} />
      <Product4 page={page} productRef={product4Ref} />
      <Contact page={page} contactRef={contactRef} />
      <Footer /> */}
    </div>
  );
};

export default Home;

function ChatBot() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);

  const dispatch = useDispatch();

  const { loading, error, authenticate, profileData } = useSelector(
    (state) => state.auth
  );

  const save = (e) => {
    e.preventDefault();

    if (email !== "" || password !== "") {
      const user = {
        email,
        password,
      };
      dispatch(login(user));
    }
  };

  console.log(profileData);

  useEffect(() => {
    dispatch(getProfile());
  }, [authenticate]);

  if (authenticate === true) {
    if (profileData.role === "Admin") {
      return <Navigate to="/product" />;
    } else if (profileData.role === "Participant") {
      return <Navigate to="/chat-engineer" />;
    }
  }
  return (
    <div className="lg:px-10">
      <div className="lg:grid lg:grid-cols-2 justify-center items-center flex flex-col-reverse lg:px-12 mt-3 relative z-20">
        <div className="mt-12 py-4 lg:px-5 px-4 terminal ">
          <div className="terminal-top flex gap-1 px-4">
            <i className="text-3xl  text-[red]">&#9679;</i>
            <i className="text-3xl  text-[#ff9900]">&#9679;</i>
            <i className="text-3xl  text-[green]">&#9679;</i>
          </div>

          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(
                  `<span>Skillnux, a bot of wonder,</span></br><span >Empowering students to ponder,</span></br><span>Lectures and books all in one place,</span></br><span>Answers to queries with speed and grace.</span></br></br><span>With AI at its very core, </span></br><span>Skillnux offers so much more, </span></br><span>No need to search, no need to roam,</span></br><span>All the knowledge, right at home. </span></br></br><span>A one-stop solution for students, </span></br><span>A tool to aid in their movements, </span></br><span>Skillnux, a friend that never tires,</span></br><span>Guiding them towards academic desires. </span>`
                )
                .start();
            }}
          />
        </div>

        <div className="mx-auto">
          <div className="login-box flex items-center">
            <form action="" onSubmit={save}>
              <div className="">
                <li className="text-center text-[#008080] text-4xl mb-8 font-medium">
                  SkillNUX
                </li>
                {/* <img src={logo} className="w-14 h-10" alt="" /> */}
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                {/* {error?.data?.email?.length > 0 && (
          <small style={{ color: "#CD5437" }}>{error?.data?.email}</small>
        )} */}
                <input
                  type={eye === false ? "password" : "text"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                {eye === false ? (
                  <i
                    className="eye fa-solid fa-eye-slash"
                    onClick={() => setEye(!eye)}></i>
                ) : (
                  <i
                    className="eye fa-solid fa-eye"
                    onClick={() => setEye(!eye)}></i>
                )}
                {/* {error?.data?.password?.length > 0 && (
          <small style={{ color: "#CD5437" }}>{error?.data?.password}</small>
        )} */}
                {error?.data?.detail?.length > 0 && (
                  <small style={{ color: "#CD5437" }}>
                    {error?.data?.detail}
                  </small>
                )}

                {/* <Link to="/forget-password">
          <li className="forget">Forget password?</li>
        </Link> */}

                <button
                  className="login-btn mt-5"
                  style={
                    email === "" || password === ""
                      ? { backgroundColor: "#ccc" }
                      : { backgroundColor: "#000" }
                  }
                  disabled={email === "" || password === "" ? true : false}
                  onClick={save}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
