import { chatConstants } from "../actions/constants";

const initState = {
  chatListData: [],
  userDocsListData: [],

  loading: false,
  error: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case chatConstants.GET_CHAT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case chatConstants.GET_CHAT_SUCCESS:
      state = {
        ...state,
        loading: false,
        chatListData: action.payload,
        error: "",
      };
      break;
    case chatConstants.GET_CHAT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
        chatListData: [],
      };
      break;

    case chatConstants.GET_USER_DOCS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case chatConstants.GET_USER_DOCS_SUCCESS:
      state = {
        ...state,
        loading: false,
        userDocsListData: action.payload,
      };
      break;
    case chatConstants.GET_USER_DOCS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case chatConstants.POST_CHAT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
  }
  return state;
};
