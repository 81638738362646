import { authConstants } from "./constants";
import axios from "../../helpers/axios";
import { api } from "../../urlConfig";

export const login = (user) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.LOGIN_REQUEST });

      const res = await axios.post(`${api}/api/token/`, {
        ...user,
      });

      if (res.status === 200) {
        const token = res.data.access;
        const user = res.config.data;

        localStorage.setItem("token", token);
        localStorage.setItem("user", user);
        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
      } else {
        if (res.status === 400) {
          dispatch({
            type: authConstants.LOGIN_FAILURE,
            payload: { error: res.data.error },
          });
        }
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          user,
        },
      });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "Failed to login" },
      });
    }
  };
};

export const getProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.PROFILE_REQUEST });

      const res = await axios.get(`${api}/u/profile/`);

      if (res.status === 200) {
        dispatch({
          type: authConstants.PROFILE_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: authConstants.PROFILE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: authConstants.PROFILE_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const changePasswordAction = (form, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstants.CHANGE_PASSWORD_REQUEST });
      const res = await axios.patch(`${api}/u/change-password/${id}/`, form);

      if (res.status === 200) {
        dispatch({
          type: authConstants.CHANGE_PASSWORD_SUCCESS,
          payload: res,
        });
      } else {
        dispatch({
          type: authConstants.CHANGE_PASSWORD_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error);
      dispatch({
        type: authConstants.CHANGE_PASSWORD_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};
