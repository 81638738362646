import { productConstants } from "./constants";
import axios from "../../helpers/axios";
import { api } from "../../urlConfig";

export const getOrgAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_ORG_REQUEST });

      const res = await axios.get(`${api}/org/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_ORG_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_ORG_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_ORG_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postOrgAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_ORG_REQUEST });
    try {
      const res = await axios.post(`${api}/org/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.POST_ORG_SUCCESS,
          payload: res.data,
        });
        dispatch(getOrgAction());
      } else {
        dispatch({
          type: productConstants.POST_ORG_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.POST_ORG_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getAircraftAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_AIRCRAFT_REQUEST });

      const res = await axios.get(`${api}/course/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_AIRCRAFT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_AIRCRAFT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_AIRCRAFT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postAircraftAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_AIRCRAFT_REQUEST });
    try {
      const res = await axios.post(`${api}/course/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.POST_AIRCRAFT_SUCCESS,
          payload: res.data,
        });
        dispatch(getAircraftAction());
      } else {
        dispatch({
          type: productConstants.POST_AIRCRAFT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.POST_AIRCRAFT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getDocsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_DOCS_REQUEST });

      const res = await axios.get(`${api}/docs/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_DOCS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_DOCS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_DOCS_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postDocsAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_DOCS_REQUEST });
    try {
      const res = await axios.post(`${api}/docs/`, form);

      if (res.status === 200) {
        dispatch({
          type: productConstants.POST_DOCS_SUCCESS,
          payload: res.data,
        });
        dispatch(getDocsAction());
      } else {
        dispatch({
          type: productConstants.POST_DOCS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.POST_DOCS_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getAircraftUserAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_AIRCRAFT_USER_REQUEST });

      const res = await axios.get(`${api}/participant/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_AIRCRAFT_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_AIRCRAFT_USER_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_AIRCRAFT_USER_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postAircraftUserAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_AIRCRAFT_USER_REQUEST });
    try {
      const res = await axios.post(`${api}/participant/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.POST_AIRCRAFT_USER_SUCCESS,
          payload: res,
        });
        dispatch(getAircraftUserAction());
      } else {
        dispatch({
          type: productConstants.POST_AIRCRAFT_USER_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err.response);
      dispatch({
        type: productConstants.POST_AIRCRAFT_USER_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getUserAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_USER_REQUEST });

      const res = await axios.get(`${api}/u/user-list/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_USER_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_USER_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_USER_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postUserAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_USER_REQUEST });
    try {
      const res = await axios.post(`${api}/u/create-user/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.POST_USER_SUCCESS,
          payload: res.data,
        });
        dispatch(getUserAction());
      } else {
        dispatch({
          type: productConstants.POST_USER_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.POST_USER_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getAircraftListAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_AIRCRAFT_LIST_REQUEST });

      const res = await axios.get(`${api}/course-list/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_AIRCRAFT_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_AIRCRAFT_LIST_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_AIRCRAFT_LIST_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getContactAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_CONTACT_REQUEST });

      const res = await axios.get(`${api}/contact-list/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.GET_CONTACT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: productConstants.GET_CONTACT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_CONTACT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postContactAction = (form) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.POST_CONTACT_REQUEST });
    try {
      const res = await axios.post(`${api}/create-form/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.POST_CONTACT_SUCCESS,
          payload: res,
        });
        dispatch(getUserAction());
      } else {
        dispatch({
          type: productConstants.POST_CONTACT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.POST_CONTACT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const deleteDocsAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.DELETE_DOCS_REQUEST });
    try {
      const res = await axios.delete(`${api}/delete-doc/${id}/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.DELETE_DOCS_SUCCESS,
          payload: res.data,
        });
        dispatch(getDocsAction());
      } else {
        dispatch({
          type: productConstants.DELETE_DOCS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.DELETE_DOCS_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const deleteParticipantAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.DELETE_PARTICIPANT_REQUEST });
    try {
      const res = await axios.delete(`${api}/participant/${id}/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.DELETE_PARTICIPANT_SUCCESS,
          payload: res.data,
        });
        dispatch(getAircraftUserAction());
      } else {
        dispatch({
          type: productConstants.DELETE_PARTICIPANT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.DELETE_PARTICIPANT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const disableCourseAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.DISABLE_COURSE_REQUEST });
    try {
      const res = await axios.put(`${api}/course/${id}/`);

      if (res.status === 200) {
        dispatch({
          type: productConstants.DISABLE_COURSE_SUCCESS,
          payload: res.data,
        });
        dispatch(getAircraftAction());
      } else {
        dispatch({
          type: productConstants.DISABLE_COURSE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.DISABLE_COURSE_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getAllChatAction = (fCourse, fCourse2, fCourse3) => {
  return async (dispatch) => {
    try {
      dispatch({ type: productConstants.GET_ALL_CHAT_REQUEST });
      if (fCourse === "" && fCourse2 === "" && fCourse3 === "") {
        const uri = "all-chat/";
        const res = await axios.get(`${api}/${uri}`);
        if (res.status === 200) {
          dispatch({
            type: productConstants.GET_ALL_CHAT_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: productConstants.GET_ALL_CHAT_FAILURE,
            payload: { error: res.data.error },
          });
        }
      } else {
        const uri = `all-chat/?org=${fCourse}&course=${fCourse2}&user=${fCourse3}`;
        const res = await axios.get(`${api}/${uri}`);
        if (res.status === 200) {
          dispatch({
            type: productConstants.GET_ALL_CHAT_SUCCESS,
            payload: res.data,
          });
        } else {
          dispatch({
            type: productConstants.GET_ALL_CHAT_FAILURE,
            payload: { error: res.data.error },
          });
        }
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: productConstants.GET_ALL_CHAT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const editCourseAction = (form, id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.EDIT_COURSE_REQUEST });
    try {
      const res = await axios.patch(`${api}/course/${id}/`, form);

      if (res.status === 200) {
        dispatch({
          type: productConstants.EDIT_COURSE_SUCCESS,
          payload: res,
        });
        dispatch(getAircraftAction());
      } else {
        dispatch({
          type: productConstants.EDIT_COURSE_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.EDIT_COURSE_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const addCreditAction = (form, id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_CREDIT_REQUEST });
    try {
      const res = await axios.post(`${api}/add-credit/${id}/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.ADD_CREDIT_SUCCESS,
          payload: res,
        });
        dispatch(getAircraftAction());
      } else {
        dispatch({
          type: productConstants.ADD_CREDIT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.ADD_CREDIT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const addCapAction = (form, id) => {
  return async (dispatch) => {
    dispatch({ type: productConstants.ADD_CREDIT_REQUEST });
    try {
      const res = await axios.post(`${api}/add-cap/${id}/`, form);

      if (res.status === 201) {
        dispatch({
          type: productConstants.ADD_CREDIT_SUCCESS,
          payload: res,
        });
        dispatch(getAircraftAction());
      } else {
        dispatch({
          type: productConstants.ADD_CREDIT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: productConstants.ADD_CREDIT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};
