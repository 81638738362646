import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  addCapAction,
  addCreditAction,
  deleteDocsAction,
  deleteParticipantAction,
  disableCourseAction,
  editCourseAction,
  getAircraftAction,
  getAircraftUserAction,
  getAllChatAction,
  getContactAction,
  getDocsAction,
  getOrgAction,
  getUserAction,
  postAircraftAction,
  postAircraftUserAction,
  postDocsAction,
  postOrgAction,
  postUserAction,
} from "../redux/actions/product.action";
import { getProfile } from "../redux/actions";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import pro1 from "../assets/pic2.jpg";
import pro2 from "../assets/pic1.jpg";

const Product = () => {
  const [tab, setTab] = useState(1);
  const [addOrg, setAddOrg] = useState(false);
  const [addAir, setAddAir] = useState(false);
  const [addDoc, setAddDoc] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [menu, setMenu] = useState(1);
  const [fCourse, setFCourse] = useState("");
  const [fCourse2, setFCourse2] = useState("");
  const [fCourse3, setFCourse3] = useState("");

  const fHandleChange = (event) => {
    setFCourse(event.target.value);
  };
  const fHandleChange2 = (event) => {
    setFCourse2(event.target.value);
  };
  const fHandleChange3 = (event) => {
    setFCourse3(event.target.value);
  };

  const dispatch = useDispatch();
  const {
    orgListData,
    aircraftListData,
    docsListData,
    aircraftUserListData,
    userListData,
    contactListData,
  } = useSelector((state) => state.productReducer);
  const { profileData } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getOrgAction());
    dispatch(getAircraftAction());
    dispatch(getDocsAction());
    dispatch(getAircraftUserAction());
    dispatch(getUserAction());
    dispatch(getProfile());
    dispatch(getContactAction());
  }, []);

  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.replace("/login");
  };

  return (
    <div>
      <div className="header flex items-center px-8 py-4 z-50 bg-[#fff]">
        <Link to="/">
          <div className="flex items-center gap-3">
            {/* <img src={logo} className="w-24 h-12 cursor-pointer" alt="" /> */}
            <span className="text-[#008080] font-medium text-3xl">
              SkillNUX
            </span>
          </div>
        </Link>
        <div>
          <li className="text-[#008080]">
            Hello <span className="font-medium ">{profileData?.username}!</span>
          </li>
        </div>
      </div>

      <div
        className="mt-16 flex relative"
        style={{ height: "calc(100vh - 65px)" }}>
        <div
          className="w-[220px] bg-black text-white p-4"
          style={{ height: "calc(100vh - 65px)" }}>
          <div className="px-4">
            <div className="mt-2">
              <i
                class={`fa-sharp fa-regular fa-building cursor-pointer text-sm ${
                  menu === 1 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(1)}></i>
              <span
                className={`ml-2 cursor-pointer text-sm ${
                  menu === 1 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(1)}>
                Organization
              </span>
            </div>
            <div className="mt-2">
              <i
                class={`fa-solid fa-user cursor-pointer text-sm ${
                  menu === 2 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(2)}></i>
              <span
                className={`ml-2 cursor-pointer text-sm ${
                  menu === 2 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(2)}>
                Users
              </span>
            </div>
            <div className="mt-2">
              <i
                class={`fa-solid fa-user cursor-pointer text-sm ${
                  menu === 3 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(3)}></i>
              <span
                className={`ml-2 cursor-pointer text-sm ${
                  menu === 3 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(3)}>
                Enquiry
              </span>
            </div>
            <div className="mt-2">
              <i
                class={`fa-solid fa-user cursor-pointer text-sm ${
                  menu === 4 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(4)}></i>
              <span
                className={`ml-2 cursor-pointer text-sm ${
                  menu === 4 ? "text-[#1dd1d1]" : "text-white"
                }`}
                onClick={() => setMenu(4)}>
                Chat
              </span>
            </div>
            <div className="absolute bottom-8">
              <div className="mt-2">
                <Link to="/settings">
                  <i class="fa-solid fa-gear text-sm"></i>
                  <span className="ml-2 text-sm">Settings</span>
                </Link>
              </div>
              <div className="mt-2 cursor-pointer" onClick={onLogout}>
                <i class="fa-solid fa-power-off text-sm"></i>
                <span className="ml-2 text-sm">Logout</span>
              </div>
            </div>
          </div>
        </div>
        {menu === 1 ? (
          <>
            <div className="flex-1 ">
              <div className="flex justify-around items-center bg-[#F2F2F2] ">
                <div className="py-2">
                  <i
                    class={`fa-sharp fa-regular fa-building cursor-pointer ${
                      tab === 1 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(1)}></i>
                  <span
                    className={`ml-2 cursor-pointer ${
                      tab === 1 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(1)}>
                    Organization
                  </span>
                </div>
                <div className="py-2">
                  <i
                    class={`fa-solid fa-graduation-cap cursor-pointer ${
                      tab === 2 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(2)}></i>
                  <span
                    className={`ml-2 cursor-pointer ${
                      tab === 2 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(2)}>
                    Course
                  </span>
                </div>
                <div className="py-2">
                  <i
                    class={`fa-brands fa-dochub cursor-pointer ${
                      tab === 3 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(3)}></i>
                  <span
                    className={`ml-2 cursor-pointer ${
                      tab === 3 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(3)}>
                    Documents
                  </span>
                </div>
                <div className="py-2">
                  <i
                    class={`fa-solid fa-user cursor-pointer ${
                      tab === 4 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(4)}></i>
                  <span
                    className={`ml-2 cursor-pointer ${
                      tab === 4 ? "text-[#008080]" : "text-black"
                    }`}
                    onClick={() => setTab(4)}>
                    Participants
                  </span>
                </div>
              </div>

              {tab === 1 ? (
                <div className="p-4 ">
                  <Buttons btn={"Add"} show={addOrg} setShow={setAddOrg} />
                  <Card
                    t1={"Organization"}
                    t2={"Email"}
                    t3={"Phone"}
                    data={orgListData}
                    col={4}
                    name="org"
                  />
                </div>
              ) : tab === 2 ? (
                <div className="p-4">
                  <Buttons
                    btn={"Add"}
                    show={addAir}
                    setShow={setAddAir}
                    name="course"
                    data={orgListData}
                    filter={fCourse3}
                    handleChange={fHandleChange3}
                  />
                  <Card
                    t1={"Course Name"}
                    t2={"Organization"}
                    t3={"Files"}
                    t4={"Participants"}
                    t5={"Credits"}
                    t6={"Caps"}
                    data={aircraftListData}
                    col={7}
                    name="aircraft"
                    fCourse={fCourse3}
                  />
                </div>
              ) : tab === 3 ? (
                <div className="p-4">
                  <Buttons
                    btn={"Add"}
                    show={addDoc}
                    setShow={setAddDoc}
                    name="docs"
                    data={aircraftListData}
                    filter={fCourse}
                    handleChange={fHandleChange}
                  />
                  <Card
                    t1={"Organization"}
                    t2={"Course"}
                    t3={"Type"}
                    t4={"Filename"}
                    data={docsListData}
                    col={5}
                    name="docs"
                    fCourse={fCourse}
                  />
                </div>
              ) : tab === 4 ? (
                <div className="p-4">
                  <Buttons
                    btn={"Add"}
                    show={addUser}
                    setShow={setAddUser}
                    name="participant"
                    data={aircraftListData}
                    filter={fCourse2}
                    handleChange={fHandleChange2}
                  />
                  <Card
                    t1={"Organization"}
                    t2={"Course"}
                    t3={"Participants"}
                    t4={""}
                    data={aircraftUserListData}
                    col={4}
                    name="aircraftuser"
                    fCourse={fCourse2}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : menu === 2 ? (
          <div className="p-4 mt-4 flex-1">
            <Buttons btn={"Add"} show={addOrg} setShow={setAddUsers} />
            <Card
              t1={"Name"}
              t2={"Email"}
              t3={"Phone"}
              data={userListData}
              col={4}
              name="user"
            />
          </div>
        ) : menu === 3 ? (
          <div className="p-4 mt-4 flex-1">
            <Card
              t1={"Name"}
              t2={"Email"}
              t3={"Phone"}
              data={contactListData}
              col={4}
              name="enquiry"
            />
          </div>
        ) : (
          <div className="p-4 mt-4 flex-1">
            <Chat />
          </div>
        )}
      </div>

      {addOrg && <Add setShow={setAddOrg} />}
      {addAir && <Add2 setShow={setAddAir} />}
      {addDoc && <Add3 setShow={setAddDoc} />}
      {addUser && <Add4 setShow={setAddUser} />}
      {addUsers && <Add5 setShow={setAddUsers} />}
    </div>
  );
};

export default Product;

function Buttons({ btn, setShow, name, data, filter, handleChange }) {
  return (
    <div className="flex justify-between">
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          setShow(true);
        }}>
        {btn} +
      </Button>
      {name === "participant" || name === "docs" || name === "course" ? (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Filter By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label="Organization"
              onChange={handleChange}>
              {data?.map((data) => {
                return (
                  <MenuItem value={data.id} key={data.id}>
                    {name === "participant" || name === "docs"
                      ? data.course_name
                      : name === "course"
                      ? data.org_name
                      : null}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ) : null}
    </div>
  );
}

function Card({ t1, t2, t3, t4, t5, t6, data, col, name, fCourse }) {
  const [delDoc, setDelDoc] = useState(false);
  const [docId, setDocId] = useState("");
  const [delPart, setDelPart] = useState(false);
  const [partId, setPartId] = useState("");
  const [delCourse, setDelCourse] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);

  const fData = data.filter((data) => data.course === fCourse);
  const fData2 = data.filter((data) => data.org === fCourse);

  return (
    <div
      style={{
        height: "calc(100vh - 200px)",
        overflowY: "scroll",
        marginTop: "20px",
      }}>
      <div
        className={`grid py-2 bg-[#ccd9ff] fixed w-4/5 z-10 ${
          col === 7 ? "grid-cols-7" : col === 5 ? "grid-cols-5" : "grid-cols-4"
        } px-8  `}>
        <span className="font-medium ">{t1}</span>
        <span className="font-medium ">{t2}</span>
        <span className="font-medium ">{t3}</span>
        {col === 5 && <span className="font-medium ">{t4}</span>}
        {col === 7 && <span className="font-medium ">{t4}</span>}
        {col === 7 && <span className="font-medium ">{t5}</span>}
        {col === 7 && <span className="font-medium ">{t6}</span>}
        <span className="font-medium">Action</span>
      </div>

      <div className="mt-[50px]">
        {name === "aircraftuser" && fCourse > 0 ? (
          fData?.map((item) => {
            return (
              <div
                className={`grid  ${
                  col === 5 ? "grid-cols-5" : "grid-cols-4"
                } px-8 mt-3`}>
                <span className="text-sm">
                  {item.course_name} {`(${item?.org[0]?.org})`}
                </span>
                <span className="text-sm">{item.course_name}</span>
                <span className="text-sm">{item.username}</span>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  className="w-28 h-8"
                  onClick={() => {
                    name === "aircraftuser" && setDelPart(true);
                    name === "aircraftuser" && setPartId(item.id);
                  }}>
                  Delete
                </Button>
              </div>
            );
          })
        ) : name === "docs" && fCourse > 0 ? (
          fData?.map((item) => {
            return (
              <div
                className={`grid ${
                  col === 5 ? "grid-cols-5" : "grid-cols-4"
                } px-8 mt-3`}>
                <span className="text-sm">{item?.org[0]?.org}</span>
                <span className="text-sm">{item.course_name}</span>
                <span className="text-sm">{item.type}</span>
                <span className="text-sm">{item.filetag}</span>
                <Button
                  size="small"
                  variant="outlined"
                  color="error"
                  className="w-28 h-8"
                  onClick={() => {
                    setDelDoc(true);
                    setDocId(item.id);
                  }}>
                  Delete
                </Button>
              </div>
            );
          })
        ) : name === "aircraft" && fCourse > 0 ? (
          fData2?.map((item) => {
            return (
              <div
                className={`grid ${
                  col === 7 ? "grid-cols-7" : "grid-cols-4"
                } px-8 mt-3`}>
                <span className="text-sm">{item.course_name}</span>
                <span className="text-sm">{item?.org[0]?.org}</span>
                <span className="text-sm">{item.file}</span>
                <span className="text-sm">{item.participant}</span>
                <span className="text-sm">{item.credit}</span>
                <span className="text-sm">{item.cap}</span>
                <div className="flex gap-4">
                  <i
                    className="cursor-pointer fa-solid fa-circle-plus text-[#70ce76]"
                    onClick={() => {
                      setAdd(true);
                      setCourseId(item);
                    }}></i>
                  <i
                    className="cursor-pointer fa-solid fa-pencil text-[#808080]"
                    onClick={() => {
                      setEdit(true);
                      setCourseId(item);
                    }}></i>
                  <i
                    className={`cursor-pointer fa-solid fa-trash ${
                      item.disable === true ? "text-[#ccc]" : "text-[#6790f7]"
                    }`}
                    onClick={() => {
                      setDelCourse(true);
                      setCourseId(item.id);
                    }}></i>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {data?.map((item) => {
              return (
                <div
                  className={`grid ${
                    col === 7
                      ? "grid-cols-7"
                      : col === 5
                      ? "grid-cols-5"
                      : "grid-cols-4"
                  } px-8 mt-3`}>
                  <span className="text-sm">
                    {name === "org" ? (
                      item.org_name
                    ) : name === "aircraft" ? (
                      item.course_name
                    ) : name === "docs" ? (
                      item?.org[0]?.org
                    ) : name === "aircraftuser" ? (
                      <span className="text-sm">
                        {item.course_name} {`(${item?.org[0]?.org})`}
                      </span>
                    ) : name === "user" ? (
                      item.username
                    ) : name === "enquiry" ? (
                      item.name
                    ) : null}
                  </span>
                  <span className="text-sm">
                    {name === "org"
                      ? item.email
                      : name === "aircraft"
                      ? item.org_name
                      : name === "docs"
                      ? item.course_name
                      : name === "aircraftuser"
                      ? item.course_name
                      : name === "user"
                      ? item.email
                      : name === "enquiry"
                      ? item.email
                      : null}
                  </span>
                  <span className="text-sm">
                    {name === "org"
                      ? item.phone
                      : name === "aircraft"
                      ? item.file
                      : name === "docs"
                      ? item.type
                      : name === "aircraftuser"
                      ? item.username
                      : name === "user"
                      ? item.phone_number
                      : name === "enquiry"
                      ? item.phone
                      : null}
                  </span>
                  {col === 5 && (
                    <span className="text-sm">
                      {name === "aircraft"
                        ? item.participant
                        : name === "aircraftuser"
                        ? item.username
                        : name === "docs"
                        ? item.filetag
                        : null}
                    </span>
                  )}
                  {col === 7 && (
                    <span className="text-sm">
                      {name === "aircraft" ? item.participant : null}
                    </span>
                  )}
                  {col === 7 && (
                    <span className="text-sm">
                      {name === "aircraft" ? item.credit : null}
                    </span>
                  )}
                  {col === 7 && (
                    <span className="text-sm">
                      {name === "aircraft" ? item.cap : null}
                    </span>
                  )}
                  {name === "docs" ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      className="w-28 h-8"
                      onClick={() => {
                        setDelDoc(true);
                        setDocId(item.id);
                      }}>
                      Delete
                    </Button>
                  ) : name !== "enquiry" && name !== "aircraft" ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color={item.disable === true ? "primary" : "error"}
                      className="w-28"
                      onClick={() => {
                        name === "aircraftuser" && setDelPart(true);
                        name === "aircraftuser" && setPartId(item.id);
                      }}>
                      {name === "aircraftuser" ? "Delete" : "Disable"}
                    </Button>
                  ) : name === "enquiry" ? (
                    <Tooltip title={item.message} placement="bottom-start">
                      <li>{item.message.substr(0, 30)}..</li>
                    </Tooltip>
                  ) : name === "aircraft" ? (
                    <div className="flex gap-4">
                      <i
                        className="cursor-pointer fa-solid fa-circle-plus text-[#70ce76]"
                        onClick={() => {
                          setAdd(true);
                          setCourseId(item);
                        }}></i>
                      <i
                        className="cursor-pointer fa-solid fa-pencil text-[#808080]"
                        onClick={() => {
                          setEdit(true);
                          setCourseId(item);
                        }}></i>
                      <i
                        className={`cursor-pointer fa-solid fa-trash ${
                          item.disable === true
                            ? "text-[#ccc]"
                            : "text-[#6790f7]"
                        }`}
                        onClick={() => {
                          setDelCourse(true);
                          setCourseId(item.id);
                        }}></i>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </>
        )}
      </div>

      {delDoc && (
        <DeleteDocs
          setShow={setDelDoc}
          id={docId}
          name="Document"
          type="Delete"
        />
      )}
      {delPart && (
        <DeleteDocs
          setShow={setDelPart}
          id={partId}
          name="Participant"
          type="Delete"
        />
      )}
      {delCourse && (
        <DeleteDocs
          setShow={setDelCourse}
          id={courseId}
          name="Course"
          type="Disable"
        />
      )}
      {edit && <EditCourse setShow={setEdit} data={courseId} />}
      {add && <AddCredit setShow={setAdd} data={courseId} />}
    </div>
  );
}

function Add({ setShow }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const dispatch = useDispatch();

  const createOrg = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("org_name", name);
    form.append("email", email);
    form.append("phone", phone);
    dispatch(postOrgAction(form));
    setShow(false);
  };

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)]  top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Add Organization</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        <div className="prod-input">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Organization"
          />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
          />
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          disabled={name === "" || email === "" || phone === "" ? true : false}
          style={
            name === "" || email === "" || phone === ""
              ? { opacity: 0.5 }
              : { opacity: 1 }
          }
          onClick={createOrg}>
          Submit
        </button>
      </div>
    </>
  );
}

function Add2({ setShow }) {
  const [name, setName] = useState("");
  const [org, setOrg] = useState("");
  const [credit, setCredit] = useState(0);
  const [cap, setCap] = useState(0);

  const dispatch = useDispatch();
  const { orgListData } = useSelector((state) => state.productReducer);

  const createAircraft = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("course_name", name);
    form.append("org", org);
    form.append("credit", credit);
    form.append("cap", cap);
    dispatch(postAircraftAction(form));
    setShow(false);
  };

  const handleChange = (event) => {
    setOrg(event.target.value);
  };

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)]  top-16 w-full h-screen z-20"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Add Course</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        <div className="prod-input">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Course Name"
          />

          <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={org}
                label="Organization"
                onChange={handleChange}>
                {orgListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.org_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <li className="font-medium mt-3">Add Credits</li>
          <input
            type="number"
            value={credit}
            onChange={(e) => setCredit(e.target.value)}
            placeholder="Add Credits"
            style={{ marginTop: "4px" }}
          />
          <li className="font-medium mt-3">Add caps</li>
          <input
            type="number"
            value={cap}
            onChange={(e) => setCap(e.target.value)}
            placeholder="Add Caps"
            style={{ marginTop: "4px" }}
          />
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          disabled={name === "" ? true : false}
          style={name === "" ? { opacity: 0.5 } : { opacity: 1 }}
          onClick={createAircraft}>
          Submit
        </button>
      </div>
    </>
  );
}

function Add3({ setShow }) {
  const [org, setOrg] = useState("");
  const [aircraft, setAircraft] = useState("");
  const [type, setType] = useState("Classroom");
  const [file, setFile] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    setOrg(event.target.value);
  };
  const handleChange2 = (event) => {
    setAircraft(event.target.value);
  };

  const handleFile = (e) => {
    // if (e.target.files[0].size > 20000000) {
    //   setError("File grater" + e.target.files[0].name + "not allowed");
    // }
    // else {
    // setFile(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    // }
  };

  const dispatch = useDispatch();
  const { orgListData, aircraftListData } = useSelector(
    (state) => state.productReducer
  );

  const createDocs = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("org", org);
    form.append("course", aircraft);
    form.append("type", type);
    form.append("file", file);
    dispatch(postDocsAction(form));
    setShow(false);
  };

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)]  top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Add Docs</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        <div className="prod-input">
          <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={org}
                label="Organization"
                onChange={handleChange}>
                {orgListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.org_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Course</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={aircraft}
                label="Aircraft"
                onChange={handleChange2}>
                {aircraftListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.course_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <div className="mt-4">
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                defaultValue="Classroom"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="Classroom"
                  control={<Radio />}
                  label="Classroom"
                  onChange={(e) => setType(e.target.value)}
                />
                <FormControlLabel
                  value="Material"
                  control={<Radio />}
                  label="Material"
                  onChange={(e) => setType(e.target.value)}
                />
              </RadioGroup>
            </FormControl>
            <li>{error}</li>
          </div>
          <input type="file" onChange={handleFile} required />
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          //  disabled={name === "" || year === "" ? true : false}
          //   style={name === "" || year === "" ? { opacity: 0.5 } : { opacity: 1 }}
          onClick={createDocs}>
          Submit
        </button>
      </div>
    </>
  );
}

function Add4({ setShow }) {
  const [aircraft, setAircraft] = useState("");
  const [user, setUser] = useState("");
  // const [role, setRole] = useState("");

  const dispatch = useDispatch();
  const {
    aircraftListData,
    userListData,
    error,
    postaircraftUserListData,
    // orgListData,
  } = useSelector((state) => state.productReducer);

  // const handleChange2 = (event) => {
  //   setRole(event.target.value);
  // };
  const handleChange3 = (event) => {
    setAircraft(event.target.value);
  };
  const handleChange4 = (event) => {
    setUser(event.target.value);
  };

  const createAircraftUser = (e) => {
    e.preventDefault();

    const form = new FormData();

    form.append("course", aircraft);
    form.append("user", user);

    dispatch(postAircraftUserAction(form));
    // setShow(false);
  };
  if (postaircraftUserListData?.status === 201) {
    window.location.reload();
  }

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)]  top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      {/* {postaircraftUserListData?.status === 201 && setShow(false)} */}
      <div
        className="bg-white top-16 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Map Participants</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        <div className="prod-input">
          {/* <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Aircraft"
                onChange={handleChange2}>
                {orgListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.org_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box> */}
          <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Course</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={aircraft}
                label="User"
                onChange={handleChange3}>
                {aircraftListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.course_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Participants
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user}
                label="Role"
                onChange={handleChange4}>
                {userListData?.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.username}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <small className="text-red">{error?.data?.msg}</small>
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          onClick={createAircraftUser}>
          Submit
        </button>
      </div>
    </>
  );
}

function Add5({ setShow }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("Participant");

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const dispatch = useDispatch();

  const createUser = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("username", name);
    form.append("email", email);
    form.append("phone_number", phone);
    form.append("role", role);
    dispatch(postUserAction(form));
    setShow(false);
  };

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)]  top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-16 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Add User</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>
        <div className="prod-input">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone"
          />
          {/* <Box sx={{ minWidth: 120, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={role}
                label="Role"
                onChange={handleChange}>
                <MenuItem value={"CAM/QM"}>CAM/QM</MenuItem>
                <MenuItem value={"Engineer"}>Engineer</MenuItem>
                <MenuItem value={"MRO"}>MRO</MenuItem>
                <MenuItem value={"Vendor"}>Vendor</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          disabled={name === "" || email === "" || phone === "" ? true : false}
          style={
            name === "" || email === "" || phone === ""
              ? { opacity: 0.5 }
              : { opacity: 1 }
          }
          onClick={createUser}>
          Submit
        </button>
      </div>
    </>
  );
}

function DeleteDocs({ setShow, id, type, name }) {
  const dispatch = useDispatch();

  const deleteDoc = (e) => {
    e.preventDefault();
    if (name === "Document") {
      dispatch(deleteDocsAction(id));
    } else if (name === "Participant") {
      dispatch(deleteParticipantAction(id));
    } else if (name === "Course") {
      dispatch(disableCourseAction(id));
    }
    setShow(false);
  };

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)] left-0 top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-0 w-[300px]  right-0 absolute p-4 z-50"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">
            {type === "Delete" ? "Delete" : "Enable/Disable"} {name}
          </span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>

        <div className="mt-8">
          <li className="text-center">
            Are you sure want to{" "}
            {type === "Delete" ? "Delete" : "Enable / Disable"} {name}?
          </li>
          <div className="flex justify-around mt-3 ">
            <button
              className="px-6 py-1 rounded"
              style={{ border: "1px solid #000" }}
              onClick={() => setShow(false)}>
              No
            </button>
            <button
              className="bg-black text-white px-6 py-1 rounded"
              onClick={deleteDoc}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function Chat() {
  const [fCourse, setFCourse] = useState("");
  const [fCourse2, setFCourse2] = useState("");
  const [fCourse3, setFCourse3] = useState("");
  const [tab, setTab] = useState(1);

  const handleChange = (event) => {
    setFCourse(event.target.value);
    dispatch(getAllChatAction(event.target.value, fCourse2, fCourse3));
  };
  const handleChange2 = (event) => {
    setFCourse2(event.target.value);
    dispatch(getAllChatAction(fCourse, event.target.value, fCourse3));
  };
  const handleChange3 = (event) => {
    setFCourse3(event.target.value);
    dispatch(getAllChatAction(fCourse, fCourse2, event.target.value));
  };

  const { allChatData, userListData, aircraftListData, orgListData, error } =
    useSelector((state) => state.productReducer);

  const orgData = allChatData.filter((data) => data.org === fCourse);
  const courseData = allChatData.filter((data) => data.course === fCourse);
  const userData = allChatData.filter((data) => data.user === fCourse);

  const dispatch = useDispatch();
  useEffect(() => {
    // const form = new FormData()
    // form.append('org', fCourse)
    // form.append('course', fCourse2)
    // form.append('user', fCourse3)

    dispatch(getAllChatAction(fCourse, fCourse2, fCourse3));
  }, []);

  console.log(allChatData);
  console.log(error);

  return (
    <div className="fixed w-4/5">
      <div className="flex gap-4 ">
        {/* <div className="gap-4 flex">
          <button
            className={`${
              tab === 1 ? "text-[#008080] font-medium" : "text-black"
            }`}
            onClick={() => setTab(1)}>
            Organization
          </button>
          <button
            className={`${
              tab === 2 ? "text-[#008080] font-medium" : "text-black"
            }`}
            onClick={() => setTab(2)}>
            Course
          </button>
          <button
            className={`${
              tab === 3 ? "text-[#008080] font-medium" : "text-black"
            }`}
            onClick={() => setTab(3)}>
            User
          </button>
        </div> */}
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Filter By Org</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fCourse}
              label="Organization"
              onChange={handleChange}>
              {orgListData?.map((data) => {
                return (
                  <MenuItem value={data.id} key={data.id}>
                    {data.org_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              Filter By Course
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fCourse2}
              label="Organization"
              onChange={handleChange2}>
              {aircraftListData?.map((data) => {
                return (
                  <MenuItem value={data.id} key={data.id}>
                    {data.course_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              Filter By User
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fCourse3}
              label="Organization"
              onChange={handleChange3}>
              {userListData?.map((data) => {
                return (
                  <MenuItem value={data.id} key={data.id}>
                    {data.username}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </div>

      <div
        style={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          marginTop: "10px",
        }}>
        <div className="overflow-y-scroll h-full">
          {error?.data === "No chat found" ? (
            <div>No -data</div>
          ) : (
            <AllChat data={allChatData} />
          )}
        </div>
      </div>
    </div>
  );
}

function AllChat(data) {
  return (
    <>
      {data.data?.length > 0
        ? data.data?.map((item) => {
            return (
              <div className="" key={item.id}>
                <div className="lg:px-32 px-8 py-4  border-b border-[#ccc] flex items-center gap-4">
                  <img
                    src={pro2}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <li className="pt-1 text-[#333] text-sm">{item.question}</li>
                </div>
                <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-center gap-4 bg-[#f5f5f5]">
                  <img
                    src={pro1}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    alt=""
                  />
                  <li className="pt-1 text-[#333] text-sm">{item.answer}</li>
                </div>
              </div>
            );
          })
        : "No-data"}
    </>
  );
}

function EditCourse({ setShow, data }) {
  const [name, setName] = useState(data.course_name);
  const [email, setEmail] = useState(data.cap);

  const dispatch = useDispatch();
  const { editCourseData, error } = useSelector(
    (state) => state.productReducer
  );
  const id = data.id;

  const createOrg = (e) => {
    e.preventDefault();

    const form = new FormData();
    const form2 = new FormData();
    form.append("course_name", name);
    form2.append("cap", email);

    dispatch(editCourseAction(form, id));
    dispatch(addCapAction(form2, id));
  };

  if (editCourseData.status === 200) {
    window.location.replace("/product");
  }

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)] left-0 top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-0 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Edit Course</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>

        <div className="prod-input mt-4">
          <li className="font-medium">Course Name</li>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Course Name"
            style={{ marginTop: "4px" }}
          />
          <li className="font-medium mt-4">Edit Caps</li>
          <input
            type="number"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Caps"
            style={{ marginTop: "4px" }}
          />
          <small className="text-[#FF9494]">{error?.data}</small>
        </div>
        <button
          className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
          onClick={createOrg}>
          Done
        </button>
      </div>
    </>
  );
}

function AddCredit({ setShow, data }) {
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const { addCreditData, error } = useSelector((state) => state.productReducer);
  const id = data.id;

  const createOrg = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("credit", name);

    dispatch(addCreditAction(form, id));
  };

  if (addCreditData.status === 201) {
    window.location.replace("/product");
  }

  return (
    <>
      <div
        className="fixed bg-[rgba(0,0,0,0.4)] left-0 top-16 w-full h-screen z-10"
        onClick={() => setShow(false)}></div>
      <div
        className="bg-white top-0 w-[300px]  right-0 absolute p-4 z-40"
        style={{ height: "calc(100vh - 65px)" }}>
        <div className="flex justify-between items-center">
          <span className="font-medium">Add Credits</span>
          <i
            class="fa-solid fa-xmark text-lg"
            onClick={() => setShow(false)}></i>
        </div>

        <div className="prod-input">
          {open === false ? (
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Add Credits"
            />
          ) : (
            <div>
              <li className="text-center">
                Are you sure want to add ${name} Credits ?
              </li>
            </div>
          )}

          <small className="text-[#FF9494]">{error?.data}</small>
        </div>
        {open === true ? (
          <button
            className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
            onClick={createOrg}>
            Done
          </button>
        ) : (
          <button
            className="bg-black text-white rounded  py-[6px] fixed bottom-4 w-[270px]"
            onClick={() => setOpen(true)}>
            Submit
          </button>
        )}
      </div>
    </>
  );
}
