import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

const PrivateRoute = () => {
  const token = window.localStorage.getItem("token");
  const { profileData } = useSelector((state) => state.auth);

  const location = useLocation();

  // console.log(location.pathname);

  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
