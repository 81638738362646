import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import chatReducer from "./chat.reducer";
import productReducer from "./product.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  productReducer: productReducer,
  chatReducer: chatReducer,
});

export default rootReducer;
