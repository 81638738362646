import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { getProfile, login } from "../redux/actions";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(false);

  const dispatch = useDispatch();

  const { loading, error, authenticate, profileData } = useSelector(
    (state) => state.auth
  );

  const save = (e) => {
    e.preventDefault();

    if (email !== "" || password !== "") {
      const user = {
        email,
        password,
      };
      dispatch(login(user));
    }
  };

  useEffect(() => {
    dispatch(getProfile());
  }, [authenticate]);

  if (authenticate === true) {
    if (profileData.role === "Admin") {
      return <Navigate to="/product" />;
    } else if (profileData.role === "Participant") {
      return <Navigate to="/chat-engineer" />;
    }
  }

  return (
    <div>
      {/* <div className=" flex bg-[#e7e7e7] px-8 pt-4">
        <Link to="/">
          <img src={logo} className="w-14 h-12 cursor-pointer" alt="" />
        </Link>
      </div> */}
      <div className="">
        <div className="login">
          <div
            className="login-box flex items-center"
            style={{ height: "90vh" }}>
            <form action="" onSubmit={save}>
              <div className="">
                <li className="text-center text-[#008080] text-4xl mb-8 font-medium">
                  SkillNUX
                </li>
                {/* <img src={logo} className="w-14 h-10" alt="" /> */}
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                {/* {error?.data?.email?.length > 0 && (
          <small style={{ color: "#CD5437" }}>{error?.data?.email}</small>
        )} */}
                <input
                  type={eye === false ? "password" : "text"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                {eye === false ? (
                  <i
                    className="eye fa-solid fa-eye-slash"
                    onClick={() => setEye(!eye)}></i>
                ) : (
                  <i
                    className="eye fa-solid fa-eye"
                    onClick={() => setEye(!eye)}></i>
                )}
                {/* {error?.data?.password?.length > 0 && (
          <small style={{ color: "#CD5437" }}>{error?.data?.password}</small>
        )} */}
                {error?.data?.detail?.length > 0 && (
                  <small style={{ color: "#CD5437" }}>
                    {error?.data?.detail}
                  </small>
                )}

                {/* <Link to="/forget-password">
          <li className="forget">Forget password?</li>
        </Link> */}

                <button
                  className="login-btn mt-5"
                  style={
                    email === "" || password === ""
                      ? { backgroundColor: "#ccc" }
                      : { backgroundColor: "#000" }
                  }
                  disabled={email === "" || password === "" ? true : false}
                  onClick={save}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
