import { chatConstants } from "./constants";
import axios from "../../helpers/axios";
import { api } from "../../urlConfig";

export const getChatAction = (id, room) => {
  return async (dispatch) => {
    try {
      dispatch({ type: chatConstants.GET_CHAT_REQUEST });

      const res = await axios.get(`${api}/chat/${id}/${room}/`);

      if (res.status === 200) {
        dispatch({
          type: chatConstants.GET_CHAT_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: chatConstants.GET_CHAT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: chatConstants.GET_CHAT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const postChatAction = (form, id, room) => {
  return async (dispatch) => {
    dispatch({ type: chatConstants.POST_CHAT_REQUEST });
    try {
      const res = await axios.post(`${api}/chat/`, form);

      if (res.status === 200) {
        dispatch({
          type: chatConstants.POST_CHAT_SUCCESS,
          payload: res.data,
        });
        dispatch(getChatAction(id, room));
      } else {
        dispatch({
          type: chatConstants.POST_CHAT_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(error.response);
      dispatch({
        type: chatConstants.POST_CHAT_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};

export const getUserDocsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: chatConstants.GET_USER_DOCS_REQUEST });

      const res = await axios.get(`${api}/user-docs/`);

      if (res.status === 200) {
        dispatch({
          type: chatConstants.GET_USER_DOCS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: chatConstants.GET_USER_DOCS_FAILURE,
          payload: { error: res.data.error },
        });
      }
    } catch (err) {
      // console.log(err);
      dispatch({
        type: chatConstants.GET_USER_DOCS_FAILURE,
        payload: { error: err.response },
      });
    }
  };
};
