export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  PROFILE_REQUEST: "PROFILE_REQUEST",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  PROFILE_FAILURE: "PROFILE_FAILURE",

  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
};

export const chatConstants = {
  GET_CHAT_REQUEST: "GET_CHAT_REQUEST",
  GET_CHAT_SUCCESS: "GET_CHAT_SUCCESS",
  GET_CHAT_FAILURE: "GET_CHAT_FAILURE",

  POST_CHAT_REQUEST: "POST_CHAT_REQUEST",
  POST_CHAT_SUCCESS: "POST_CHAT_SUCCESS",
  POST_CHAT_FAILURE: "POST_CHAT_FAILURE",

  GET_USER_DOCS_REQUEST: "GET_USER_DOCS_REQUEST",
  GET_USER_DOCS_SUCCESS: "GET_USER_DOCS_SUCCESS",
  GET_USER_DOCS_FAILURE: "GET_USER_DOCS_FAILURE",
};

export const productConstants = {
  GET_ORG_REQUEST: "GET_ORG_REQUEST",
  GET_ORG_SUCCESS: "GET_ORG_SUCCESS",
  GET_ORG_FAILURE: "GET_ORG_FAILURE",

  POST_ORG_REQUEST: "POST_ORG_REQUEST",
  POST_ORG_SUCCESS: "POST_ORG_SUCCESS",
  POST_ORG_FAILURE: "POST_ORG_FAILURE",

  GET_AIRCRAFT_REQUEST: "GET_AIRCRAFT_REQUEST",
  GET_AIRCRAFT_SUCCESS: "GET_AIRCRAFT_SUCCESS",
  GET_AIRCRAFT_FAILURE: "GET_AIRCRAFT_FAILURE",

  POST_AIRCRAFT_REQUEST: "POST_AIRCRAFT_REQUEST",
  POST_AIRCRAFT_SUCCESS: "POST_AIRCRAFT_SUCCESS",
  POST_AIRCRAFT_FAILURE: "POST_AIRCRAFT_FAILURE",

  GET_DOCS_REQUEST: "GET_DOCS_REQUEST",
  GET_DOCS_SUCCESS: "GET_DOCS_SUCCESS",
  GET_DOCS_FAILURE: "GET_DOCS_FAILURE",

  POST_DOCS_REQUEST: "POST_DOCS_REQUEST",
  POST_DOCS_SUCCESS: "POST_DOCS_SUCCESS",
  POST_DOCS_FAILURE: "POST_DOCS_FAILURE",

  DELETE_DOCS_REQUEST: "DELETE_DOCS_REQUEST",
  DELETE_DOCS_SUCCESS: "DELETE_DOCS_SUCCESS",
  DELETE_DOCS_FAILURE: "DELETE_DOCS_FAILURE",

  GET_AIRCRAFT_USER_REQUEST: "GET_AIRCRAFT_USER_REQUEST",
  GET_AIRCRAFT_USER_SUCCESS: "GET_AIRCRAFT_USER_SUCCESS",
  GET_AIRCRAFT_USER_FAILURE: "GET_AIRCRAFT_USER_FAILURE",

  POST_AIRCRAFT_USER_REQUEST: "POST_AIRCRAFT_USER_REQUEST",
  POST_AIRCRAFT_USER_SUCCESS: "POST_AIRCRAFT_USER_SUCCESS",
  POST_AIRCRAFT_USER_FAILURE: "POST_AIRCRAFT_USER_FAILURE",

  GET_AIRCRAFT_LIST_REQUEST: "GET_AIRCRAFT_LIST_REQUEST",
  GET_AIRCRAFT_LIST_SUCCESS: "GET_AIRCRAFT_LIST_SUCCESS",
  GET_AIRCRAFT_LIST_FAILURE: "GET_AIRCRAFT_LIST_FAILURE",

  DELETE_PARTICIPANT_REQUEST: "DELETE_PARTICIPANT_REQUEST",
  DELETE_PARTICIPANT_SUCCESS: "DELETE_PARTICIPANT_SUCCESS",
  DELETE_PARTICIPANT_FAILURE: "DELETE_PARTICIPANT_FAILURE",

  DISABLE_COURSE_REQUEST: "DISABLE_COURSE_REQUEST",
  DISABLE_COURSE_SUCCESS: "DISABLE_COURSE_SUCCESS",
  DISABLE_COURSE_FAILURE: "DISABLE_COURSE_FAILURE",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",

  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCESS: "POST_USER_SUCCESS",
  POST_USER_FAILURE: "POST_USER_FAILURE",

  GET_CONTACT_REQUEST: "GET_CONTACT_REQUEST",
  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAILURE: "GET_CONTACT_FAILURE",

  POST_CONTACT_REQUEST: "POST_CONTACT_REQUEST",
  POST_CONTACT_SUCCESS: "POST_CONTACT_SUCCESS",
  POST_CONTACT_FAILURE: "POST_CONTACT_FAILURE",

  GET_ALL_CHAT_REQUEST: "GET_ALL_CHAT_REQUEST",
  GET_ALL_CHAT_SUCCESS: "GET_ALL_CHAT_SUCCESS",
  GET_ALL_CHAT_FAILURE: "GET_ALL_CHAT_FAILURE",

  EDIT_COURSE_REQUEST: "EDIT_COURSE_REQUEST",
  EDIT_COURSE_SUCCESS: "EDIT_COURSE_SUCCESS",
  EDIT_COURSE_FAILURE: "EDIT_COURSE_FAILURE",

  ADD_CREDIT_REQUEST: "ADD_CREDIT_REQUEST",
  ADD_CREDIT_SUCCESS: "ADD_CREDIT_SUCCESS",
  ADD_CREDIT_FAILURE: "ADD_CREDIT_FAILURE",
};
