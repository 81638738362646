import { productConstants } from "../actions/constants";

const initState = {
  orgListData: [],
  contactListData: [],
  postContactData: [],
  aircraftListData: [],
  aircraftUserListData: [],
  aircraftListData2: [],
  postaircraftUserListData: [],
  userListData: [],
  docsListData: [],
  allChatData: [],
  addCreditData: [],
  editCourseData: [],
  loading: false,
  error: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case productConstants.GET_ORG_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_ORG_SUCCESS:
      state = {
        ...state,
        loading: false,
        orgListData: action.payload,
      };
      break;
    case productConstants.GET_ORG_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_AIRCRAFT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_AIRCRAFT_SUCCESS:
      state = {
        ...state,
        loading: false,
        aircraftListData: action.payload,
      };
      break;
    case productConstants.GET_AIRCRAFT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_AIRCRAFT_USER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_AIRCRAFT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        aircraftUserListData: action.payload,
      };
      break;
    case productConstants.GET_AIRCRAFT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.POST_AIRCRAFT_USER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.POST_AIRCRAFT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        postaircraftUserListData: action.payload,
      };
      break;
    case productConstants.POST_AIRCRAFT_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_AIRCRAFT_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_AIRCRAFT_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        aircraftListData2: action.payload,
      };
      break;
    case productConstants.GET_AIRCRAFT_LIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_USER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        userListData: action.payload,
      };
      break;
    case productConstants.GET_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_DOCS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_DOCS_SUCCESS:
      state = {
        ...state,
        loading: false,
        docsListData: action.payload,
      };
      break;
    case productConstants.GET_DOCS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_CONTACT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_CONTACT_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactListData: action.payload,
      };
      break;
    case productConstants.GET_CONTACT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.POST_CONTACT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.POST_CONTACT_SUCCESS:
      state = {
        ...state,
        loading: false,
        postContactData: action.payload,
      };
      break;
    case productConstants.POST_CONTACT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.GET_ALL_CHAT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case productConstants.GET_ALL_CHAT_SUCCESS:
      state = {
        ...state,
        loading: false,
        allChatData: action.payload,
        error: "",
      };
      break;
    case productConstants.GET_ALL_CHAT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.ADD_CREDIT_REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case productConstants.ADD_CREDIT_SUCCESS:
      state = {
        ...state,
        loading: false,
        addCreditData: action.payload,
      };
      break;
    case productConstants.ADD_CREDIT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case productConstants.EDIT_COURSE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case productConstants.EDIT_COURSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        editCourseData: action.payload,
      };
      break;
    case productConstants.EDIT_COURSE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
  }
  return state;
};
