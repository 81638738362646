import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

const Chat = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [ask, setAsk] = useState("");
  const [q, setQ] = useState(false);
  const [qn, setQn] = useState("");
  const [ans, setAns] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const chatRef = useRef(null);
  useEffect(() => {
    chatRef.current?.scrollIntoView();
  }, [data]);

  useEffect(() => {
    handleData();
  }, [user]);

  const handleData = async () => {
    await axios
      .get(`http://127.0.0.1:8000/chat/${user}/`)
      .then((response) => setData(response.data))
      .catch((error) => console.error(error));
  };

  const send = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("user", user);
    form.append("question", ask);
    setQ(true);
    setQn(ask);
    setAsk("");
    setLoading(true);
    console.log(form);

    await axios
      .post(`http://127.0.0.1:8000/chat/`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatus(response.status);
        setAns(response.data);
        setQ(false);
        setLoading(false);
      })
      .catch((error) => console.error(error));
    handleData();
  };

  const del = async (e) => {
    e.preventDefault();

    await axios
      .delete(`http://142.93.210.6:8000/api/`)
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
    handleData();
  };

  return (
    <div className="lg:flex">
      <div className="flex w-1/5">
        <Menu show={show} del={del} setUser={setUser} user={user} />
      </div>
      <div className="flex-1 bg-white py-4 mt-12 lg:mt-0">
        <ChatBox
          data={data}
          ask={ask}
          setAsk={setAsk}
          send={send}
          chatRef={chatRef}
          q={q}
          qn={qn}
          ans={ans}
          status={status}
          user={user}
        />
      </div>
      <div className="lg:hidden pl-8 bg-black text-white p-4 fixed top-0 w-full">
        <i className="fa-solid fa-bars" onClick={() => setShow(!show)}></i>
      </div>
    </div>
  );
};

export default Chat;

function ChatBox({ data, ask, setAsk, send, chatRef, q, qn, ans, status }) {
  return (
    <div className="relative mb-20">
      {data?.map((item) => (
        <div className="" key={item.id}>
          <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-start gap-4">
            <i className="fa-solid fa-user  text-lg"></i>
            <li className="pt-1 text-[#333]">{item.question}</li>
          </div>
          <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-start gap-4 bg-[#f5f5f5]">
            <i className="fa-solid fa-user-tie  text-lg"></i>
            <li className="pt-1 text-[#333] ">{item.answer}</li>
          </div>
        </div>
      ))}

      <div className="mb-10">
        {q === true ? (
          <div className="lg:px-32 px-8 py-4 border-b border-[#aaa] flex items-start gap-4 bg-[#fff]">
            <i className="fa-solid fa-user  text-lg"></i>
            <li className="">{qn}</li>
          </div>
        ) : null}
        {/* {q === true && status === 201 ? (
            <div className="lg:px-32 px-8 py-4 border-b border-[#ccc] flex items-start gap-4 bg-[#ff9900]">
              <i className="fa-solid fa-user-tie  text-lg"></i>
              <li className=" ">{ans}</li>
            </div>
          ) : null} */}
      </div>

      <div ref={chatRef} />
      <div className=" w-full left-0 fixed bottom-0 bg-white ">
        <div className="lg:ml-96 lg:mr-28 relative  p-4">
          <form action="" onSubmit={send}>
            <input
              className="chat-input"
              type="text"
              placeholder="write something"
              value={ask}
              onChange={(e) => setAsk(e.target.value)}
            />

            <i
              className="fa-solid fa-paper-plane absolute right-8 top-8"
              onClick={send}></i>
          </form>
        </div>
      </div>
    </div>
  );
}

function Menu({ show, del, setUser, user }) {
  return (
    <div
      className={`fixed left-0 top-0 bg-black w-1/5 h-screen text-white p-4 z-10 lg:block  ${
        show === true ? "w-screen top-10" : "hidden"
      }`}>
      <div className="grid gap-2 lg:px-2 px-4 justify-start">
        <button
          className={user === 1 ? "text-red" : "text-white"}
          onClick={() => setUser(1)}>
          Store Manager
        </button>
        <button
          className={user === 2 ? "text-red" : "text-white"}
          onClick={() => setUser(2)}>
          Inventory Manager
        </button>

        {/* <div className="flex gap-2 ">
          <i className="fa-regular text-sm fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9BloodDonation.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm">
            Blood Donation Leave
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9-cld.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm">
            Catastrophic Leave Donation
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9_family.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm">
            Family Leaves for Nonacademic Staff
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9-hpl.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm ">
            Health and Personal Leave for Staff
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_2_1_0.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm ">
            Leaves for Professors and Academic Staff
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9_medical_1.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm ">
            Medical Leaves for Nonacademic Staff
          </a>
        </div>
        <div className="flex gap-2 ">
          <i className="fa-regular text-sm  fa-file"></i>
          <a
            href="https://policy.cornell.edu/sites/default/files/vol6_9_Military_Leave.pdf"
            rel="noreferrer"
            target="_blank"
            className="text-sm ">
            Military Leave Amendment
          </a>
        </div> */}

        <button className="absolute bottom-4" onClick={del}>
          <i className="fa-solid fa-trash mr-2 text-sm "></i> Clear Chat
        </button>
      </div>
    </div>
  );
}
